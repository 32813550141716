<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton
                :showBtn="true"
                :showAddNew="false"
                title="Pharmacy Bill"
                @onClickCloseButton="goToList"
            >
                <template #extra-btn>
                    <div class="template-selection">
                        <label class="form-label">Select Template</label>
                        <AsyncSelect
                            placeholder="Select Template"
                            v-model="template"
                            class="w-100"
                            :additional-query="{ template_type: 'sale'}"
                            :api-service="fetchTemplates"
                        />
                    </div>
                </template>
            </TitleButton>
        </div>

        <div class="my-2 px-2">
            <TableHeaderData
                :formData="formData"
                :tableItems="tableItems"
                :additionalProductQuery="additionalProductQuery"
                ref="formHeader"
            />
        </div>

        <div class="col-12 px-2">
            <ListTable
                :tableItems="tableItems"
                @addNewItem="addNewItem"
                :salesAccountHeads="salesAccountHeads"
                :vatRate="vatRate"
            />
        </div>
        <div class="row px-2">
            <div class="col-md-6">
                <div class="mb-1">
                    <label class="form-label" for="description">Ledger Memo</label>
                    <vField
                        as="textarea"
                        name="description"
                        type="number"
                        class="form-control"
                        v-model="formData.description"
                    />
                </div>
            </div>
            <div class="col-md-5 ml-auto mt-auto mb-1">
                <div class="d-flex flex-wrap gap-1 gy-2">
                    <button :disabled="submitLoading" @click="handleSubmit(false)" class="btn btn-primary">Save</button>
                    <button :disabled="submitLoading" @click="handleSubmit(true)" class="btn btn-primary">Save & Print</button>
                </div>
            </div>
        </div>

        <Loader v-if="loader"/>
    </div>
</template>

<script setup>
import {computed, inject, onMounted, provide, ref} from 'vue';
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/hospital/general-billing/v2/InvoiceHospitalTable.vue';
import TableHeaderData from '@/components/atom/hospital/pharmacy-bill-v2/TableHeaderInvoiceHospital.vue';
import {useRoute, useRouter} from 'vue-router';
import handlePurchase from '@/services/modules/purchase';
import {vatRate} from '@/data/inventory.js';
import handleCompany from "@/services/modules/company";
import hospitalPdfPrinterHelper from '@/services/utils/hospitalPdfPrinterHelper';
import useDate from "@/services/utils/day";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleTemplate from '@/services/modules/all-modules/template'
import { watch } from 'vue';
import Loader from '@/components/atom/LoaderComponent'

    const { generatePdf } = hospitalPdfPrinterHelper();
    const { fetchSalesBill, ...rest } = handlePurchase();
    const { fetchCompanyInfo } = handleCompany();
    const { removeTemplate, fetchTemplates } = handleTemplate();
    const $route = useRoute();
    const $router = useRouter();
    const date = useDate();

    const companyId = $route.params.companyId;
    const loader = ref(true);
    const submitLoading = ref(false);
    const formHeader = ref(null);
    const salesAccountHeads = ref([]);
    const totals = ref({});
    const invoiceRes = ref({});
    const company = ref({});
    const template = ref(null);
    let formData = ref({
        company_id: companyId,
        contact_profile_id: null,
        account_head_id: null,
        ipd_register_id: null,
        vat_payable_account_head_id: null,
        project_id: null,
        cost_centre_id: null,
        location_id: null,
        mop_account_head_id: null,
        discount_head_id: null,
        service_resource_id: null,
        receipt_reference: null,
        adjustment_amount: null,
        receipt_description: "Pharmacy bill payment",
        payment: null,
        status: 'active',
        date: '',
        has_item_detail: true,
        description: '',
        order_number: null,
        sales_person_id: null,
        commission_percent: null,
        lc_number: null,
        item_details: []
    });
    provide('formData', formData);
    provide('totals', totals);
    const showSuccess =  inject('showSuccess');
    const showError =  inject('showError');

    const additionalProductQuery = computed(() => {
        return {
            location_id: formData.value.location_id,
            groupByBatch: 1
        }
    })

    onMounted( async() => {
        loader.value=true
        const companyQuery = `?company_id=${companyId}`;
        Promise.all([
            rest.fetchAccountReceivable(companyQuery).then(res=> {
                if(res.data) formData.value.account_head_id = res.data[0].id;
            }),
            rest.fetchVatPayable(companyQuery).then(res=> {
                if(res.data) formData.value.vat_payable_account_head_id = res.data[0].id;
            }),
            fetchCompanyInfo(companyId).then(res=> {
                if(res.data) company.value = res.data;
            }),
            rest.getAccountHeadBySlag("discount_account", companyQuery).then(res => {
                if(res.data) {
                  salesAccountHeads.value = res.data
                }
            })
        ])
        .finally(() => {loader.value=false})
    })

    let tableItems = ref([]);

    const addNewItem = () => {

        tableItems.value.push({
            product_id: null,
            name: '',
            description: '',
            quantity : 1,
            rate: 0,
            discount_percent: 0,
            vat: 0,
            sub_total: 0,
            discount_amount: 0,
            vat_amount: 0,
            total: 0,
            isEdit: false,
        });

    }

    const goToList = () => {
        $router.push({name: 'pharmacy-bill-list',
            params: {
                companyId: $route.params.companyId,
                moduleId: $route.params.moduleId,
                menuId: $route.params.menuId,
                pageId: $route.params.pageId
            },
            query: {
                start: $route.query.start,
                end: $route.query.end
            }

        })
    }

    const resetForm = () => {
        const accountHeadId = formData.value.account_head_id
        const vatPayableAccountHeadId = formData.value.vat_payable_account_head_id
        formData.value = {
            company_id: companyId,
            contact_profile_id: null,
            ipd_register_id: null,
            project_id: null,
            account_head_id: accountHeadId,
            cost_centre_id: null,
            location_id: null,
            mop_account_head_id: null,
            vat_payable_account_head_id: vatPayableAccountHeadId,
            discount_head_id: null,
            service_resource_id: null,
            receipt_reference: null,
            adjustment_amount: null,
            receipt_description: "Pharmacy bill payment",
            payment: null,
            status: 'active',
            date: '',
            has_item_detail: true,
            description: '',
            order_number: null,
            sales_person_id: null,
            commission_percent: null,
            lc_number: null,
            item_details: []
        };
        formHeader.value.resetData()
        tableItems.value = [];
    }

    const handleSubmit = (print=false) => {
        submitLoading.value = true;
        if(!formData.value.date) formData.value.date = date.currentDate();
        formData.value.item_details = tableItems;
        let copyFormData = JSON.parse(JSON.stringify(formData.value))
        copyFormData.item_details = formData.value.item_details
        copyFormData.sale_type = "pharmacy_invoice"
        copyFormData.receipt_type = "pharmacy_invoice"
        copyFormData.with_batch = 1

        rest.storeBillPayment(copyFormData)
        .then(res => {
            if(res.status) {
                showSuccess(res.message)
                if(print) goToPrint(res.data.id)
                resetForm();
                submitLoading.value = false;
                return;
            }
            showError(res.message)
            submitLoading.value = false;
        })
        .catch(err=> {
            console.log(err)
            showError(err.response.data.message)
            submitLoading.value = false;
        })
    }

    const goToPrint = (id) => {
        const companyQuery = `?company_id=${companyId}`;

        fetchSalesBill(id, companyQuery).then( (res) =>{
            if( res ){
                invoiceRes.value = res.data;
            }
        }).then( () => {
            generatePdf(company.value, invoiceRes.value, 'BILL', true, true)
        }).catch( (err) => {
            console.log(err);
        })
    }

    const getProduct = (item) => {
        if(! item.product) return null;

        const product = {
            id: item.product.id,
            product_id: item.product.id,
            name: item.product.name,
            quantity: item.quantity,
            stock_in_hand: item.stock_in_hand,
            rate:  item.rate,
            amount:  item.amount,
            vat: item.vat,
            vat_amount:  item.vat_amount,
            discount_percent: item.discount_percent,
            discount_amount: item.discount_amount,
            description: item.product.description && item.product.description.unit ? item.product.description.unit.name : '',
            isEdit: false
        }
    
        return product;
    }

    const setTableItems = (invoice) => {
        for(const item of invoice.general) {
            const productDetails = getProduct(item);
            if(! productDetails) continue;

            tableItems.value.push(productDetails);
        }
    }

    const getSingleSalesBill = async () => {

        const id = template.value && template.value.templateable_id;
        if(!id) return;

        const companyQuery = `?company_id=${companyId}`;
        loader.value = true;

        await fetchSalesBill(id, companyQuery).then( (res) =>{
            if(! res.data) return;
            setTableItems(res.data);
        }).catch( (err) => {
            console.log(err);
        }).finally(() => {
            loader.value = false;
        })
    }

    watch(template, (newValue, oldValue) => {
        if(newValue) {
            getSingleSalesBill();
        }
    });

</script>
<style scoped>
address{
    margin-bottom: 0 !important;
}
.template-selection {
    margin-left: -97%;
    width: 166%;
}
</style>
